import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StarIcon from "@material-ui/icons/Star";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClockLoader from "react-spinners/ClockLoader";
import Snackbar from "@material-ui/core/Snackbar";
import { css } from "@emotion/core";

import "./newProductPages.scss";
import CMOButton from "../../components/Button/Button";
import Slider from "react-slick";
import { navigate } from "gatsby";
import ReactMarkdown from "react-markdown";
import { Close } from "@material-ui/icons";
import recaptchaRef from "../ContactForm/recaptchaRef";
import ReCAPTCHA from "react-google-recaptcha";
import CMOCheckbox from "../../templates/ContactForm/Checkbox/Checkbox";
import SuccessCheckMark from "../../components/SuccessCheckMark/SuccessCheckMark";
import { Alert } from "../RequestForm/RequestForm";
import { Fragment } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  NextArrow,
  PrevArrow,
} from "../../components/TitleDescCarousel/TitleDescCarousel";
import NumberFormat from "react-number-format";
import rehypeRaw from "rehype-raw";

class NewProductPages extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      configuration: {},
      img:
        process.env.GATSBY_CMS_URL +
        this.props.pageContext.content.ImageColorVariant[0].image.url,
      imgAlt: this.props.pageContext.content.ImageColorVariant[0].image_alt,
      imgTitle: this.props.pageContext.content.ImageColorVariant[0].image_description,
      isModalOpen: false,
      form: {
        contactName: "",
        taxNumber: "",
        companyName: "",
        emailAddress: "",
        phone: "",
        remarks: "",
        agreement: false,
      },
      errors: {
        contactNameError: false,
        taxNumberError: false,
        companyNameError: false,
        emailAddressError: "",
        phoneError: false,
        agreementError: false,
        snackbarError: false,
      },
      emailSuccess: false,
      loader: false,
      snackbar: false,
      buttonDisabled: false
    };

    this.isOptionSelected = this.isOptionSelected.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.getResult = this.getResult.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.contactNameValidation = this.contactNameValidation.bind(this);
    this.companyNameValidation = this.companyNameValidation.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.phoneValidation = this.phoneValidation.bind(this);
    this.emailCheck = this.emailCheck.bind(this);
    this.taxNumberValidation = this.taxNumberValidation.bind(this);
    this.agreementValidation = this.agreementValidation.bind(this);
    this.formvalidations = this.formvalidations.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resolveProductCategorySlug =
      this.resolveProductCategorySlug.bind(this);
  }

  resolveProductCategorySlug(id) {
    if (!id) {
      return "";
    }
    return this.props.pageContext.productCategories.find(
      (category) => category._id === id
    )?.slug;
  }

  NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <NavigateNextIcon
        className={className}
        style={{
          ...style,
          color: "#707070",
          width: 100,
          height: 100,
          padding: 2,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <NavigateBeforeIcon
        className={className}
        style={{
          ...style,
          color: "#707070",
          width: 100,
          height: 100,
          padding: 2,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  getResult() {
    if( this.state.buttonDisabled )
      return;

    this.setState( {buttonDisabled: true} );
    recaptchaRef.current.execute();
  }

  handleSnackBarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      snackbar: false,
      errors: { ...this.state.errors, snackbarError: false },
    });
  }

  contactNameValidation() {
    if (this.state.form.contactName === "") {
      this.setState({
        errors: { ...this.state.errors, contactNameError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, contactNameError: false },
      });
      return true;
    }
  }

  companyNameValidation() {
    if (this.state.form.companyName === "") {
      this.setState({
        errors: { ...this.state.errors, companyNameError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, companyNameError: false },
      });
      return true;
    }
  }

  emailValidation() {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(this.state.form.emailAddress);
  }

  phoneValidation() {
    if (this.state.form.phone === "") {
      this.setState({ errors: { ...this.state.errors, phoneError: true } });
      return false;
    } else {
      this.setState({ errors: { ...this.state.errors, phoneError: false } });
      return true;
    }
  }

  emailCheck() {
    if (this.state.form.emailAddress === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          emailAddressError: "Kötelezően kitöltendő",
        },
      });
      return false;
    } else {
      if (this.emailValidation()) {
        this.setState({
          errors: { ...this.state.errors, emailAddressError: "" },
        });
        return true;
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            emailAddressError: "Nem megfelelő formátum",
          },
        });
        return false;
      }
    }
  }

  taxNumberValidation() {
    if (this.state.form.taxNumber === "") {
      this.setState({ errors: { ...this.state.errors, taxNumberError: true } });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, taxNumberError: false },
      });
      return true;
    }
  }

  agreementValidation() {
    if (this.state.form.agreement === false) {
      this.setState({ errors: { ...this.state.errors, agreementError: true } });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, agreementError: false },
      });
      return true;
    }
  }

  async formvalidations() {
    let contactNameResult = await this.contactNameValidation();
    let companyNameResult = await this.companyNameValidation();
    let emailAddressResult = await this.emailCheck();
    let phoneResult = await this.phoneValidation();
    let taxNumberResult = await this.taxNumberValidation();
    let agreementResult = await this.agreementValidation();

    if (
      contactNameResult === false ||
      companyNameResult === false ||
      emailAddressResult === false ||
      phoneResult === false ||
      taxNumberResult === false ||
      agreementResult === false
    ) {
      this.setState({ emailSuccess: false });
      return false;
    } else {
      return true;
    }
  }

  onChange(token) {
    this.setState({ loader: true, buttonDisabled: true });

    const { contactName, companyName, emailAddress, phone, taxNumber, remarks } =
      this.state.form;

    fetch(`${process.env.GATSBY_AZURE_URL}/request-offer`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        recaptcha: token,
        surname: contactName,
        firstName: "",
        companyName,
        email: emailAddress,
        phoneNumber: phone,
        taxNumber,
        remark: remarks,
        productKey: this.props.pageContext.content.productKey,
      }),
    })
      .then((data) => {
        if (data.status === 200) {
          navigate("/thank-you" + this.props.pageContext.content.Slug, { replace: false });
        } else {
          this.setState({
            buttonDisabled: false,
            loader: false,
            emailSuccess: false,
            errors: { ...this.state.errors, snackbarError: true },
          });
        }
      })
      .catch((e) => {
        this.setState({
          buttonDisabled: false,
          loader: false,
          emailSuccess: false,
          errors: { ...this.state.errors, snackbarError: true },
        });
      });
  }

  componentDidMount() {
    this.props.pageContext.content.Configuration_field.forEach((field) => {
      const newConfig = this.state.configuration;
      newConfig[field.key] = field.Configuration_item[0].key;
      this.setState({ configuration: newConfig });
    });
  }

  isOptionSelected(fieldKey, itemKey) {
    return this.state.configuration[fieldKey] === itemKey;
  }

  calculatePrice(type = "net") {
    const { deposit, shipping, installationFee, monthlyFee } =
      this.props.pageContext.content.Financial_data;

    const additiveItems = [];
    this.props.pageContext.content.Configuration_field.filter(
      (field) => field.priceType === "additive"
    ).forEach((field) => {
      additiveItems.push(
        ...field.Configuration_item.filter(
          (item) => this.state.configuration[field.key] === item.key
        )
      );
    });

    const multiplicativeItems = [];
    this.props.pageContext.content.Configuration_field.filter(
      (field) => field.priceType === "multiplicative"
    ).forEach((field) => {
      multiplicativeItems.push(
        ...field.Configuration_item.filter(
          (item) => this.state.configuration[field.key] === item.key
        )
      );
    });

    const price =
      additiveItems
        .map((item) => item.price)
        .reduce((acc, current) => acc + current, 0) *
      multiplicativeItems
        .map((item) => item.price)
        .reduce((acc, current) => acc * current, 1);

    if (type === "net") {
      return Math.round(
        price + deposit.price + shipping.price + installationFee.price
        //monthlyFee.price
      );
    } else {
      return Math.round(
        price * 1.27 +
          deposit.price +
          shipping.price * 1.27 +
          installationFee.price * 1.27
        // monthlyFee.price * 1.27
      );
    }
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  handleModalClose() {
    this.setState({
      isModalOpen: false,
      emailSuccess: false,
      form: {
        contactName: "",
        taxNumber: "",
        companyName: "",
        emailAddress: "",
        phone: "",
        agreement: false,
      },
      errors: {
        contactNameError: false,
        taxNumberError: false,
        companyNameError: false,
        emailAddressError: "",
        phoneError: false,
        agreementError: false,
        snackbarError: false,
      },
    });
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 100px;
      box-shadow: none;
      border: 4px solid #4caf50;
    `;

    const location_href = this.props.location.pathname;

    const {
      Primary_color,
      Secondary_color,
      Seo,
      Button,
      Product_included,
      title,
      Financial_data,
      testimonials,
      Buzzword_list,
      paymentMethods,
      relatedProducts,
      penztargep_es_pos,
      penztargep,
      terminal,
      szamlazo,
      qr_fizetes,
      SuccessText,
      description,
      richTextDescription,
      detailedDescription,
      bottomDescription,
      formTitle,
      dialogMonthlyFeeText,
      dialogSummaryText,
      bottomImage,
      bottomImageAlt,
      IsActiveOnProduction,
      offerFormDescription
    } = this.props.pageContext.content;

    const testimonialsSliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      className: "testimonials-slider",
      lazyload: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const relatedProductsSliderSettings = {
      infinite: relatedProducts.length > 3,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: "related-products-slider",
      lazyload: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            arrows: true,
            infinite: true,
          },
        },
      ],
    };

    return (
      <LayoutHeaderFooter
        header_background_color={Primary_color}
        header_secondary_color={Secondary_color}
        header_no_fade={true}
        SEO_title={Seo.title}
        SEO_location={location_href}
        SEO_description={Seo.description}
        SEO_keywords={Seo.keywords}
        SEO_image={Seo.image}
        main_class={"new-product-page"}
      >
        { !IsActiveOnProduction && <h1>Ez az oldal nincs aktiválva éles oldalon!!!!!</h1> }
        <div className="product">
          <div className="left-section">
            <img
              alt={this.state.imgAlt}
              className="desktop-img"
              src={this.state.img}
              title={this.state.imgTitle}
              loading="lazy"
            ></img>
          </div>
          <div className="configurator">
            <h1>{title}</h1>
            <div className="included-products-container">
              {Product_included.map((product, productIndex) => (
                <div
                  className="included-product"
                  key={`product-${productIndex}`}
                >
                  <ArrowRightAltIcon className="arrow" />
                  {product.productName}
                </div>
              ))}
            </div>
            <img
              alt={this.state.imgAlt}
              className="mobile-img"
              src={this.state.img}
              title={this.state.imgTitle}
              loading="lazy"
            ></img>
            { 
              richTextDescription 
                ? <ReactMarkdown rehypePlugins={[rehypeRaw]} children={richTextDescription} />
                : <span className="description no-config">{description}</span>
            }
            <CMOButton
                variant="contained"
                text="Érdekel az ajánlat!"
                background_color="#00b8ac"
                color={"white"}
                onClick={this.openModal}
              />
          </div>
        </div>
        { detailedDescription &&
          <div className="detailed-description-container">
          <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={detailedDescription}
              ></ReactMarkdown>
            </div>
        }
        { Financial_data && (
        <div className="starter-cost">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={Financial_data.fixCostDescription}
          ></ReactMarkdown>
          <div className="right-section">
            <span className="net-price">
              <span className="net-text">nettó</span>
              <NumberFormat
                value={this.calculatePrice("net")}
                thousandSeparator={" "}
                displayType="text"
                renderText={(value) => (
                  <span className="net-number">{value} Ft</span>
                )}
              ></NumberFormat>
            </span>
            <span className="gross-price">
              <span className="gross-text">bruttó</span>
              <NumberFormat
                value={this.calculatePrice("gross")}
                thousandSeparator={" "}
                displayType="text"
                renderText={(value) => (
                  <span className="gross-number">{value} Ft</span>
                )}
              ></NumberFormat>
            </span>
          </div>
        </div>
        )}
         { Financial_data && (<div className="monthly-cost">
          <div className="left-section">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={Financial_data.monthlyFeeDescription}
            ></ReactMarkdown>
          </div>
          <div className="right-section">
            <span className="net-price">
              <span className="net-text">nettó</span>
              <NumberFormat
                value={Financial_data.monthlyFee.price}
                thousandSeparator={" "}
                displayType="text"
                renderText={(value) => (
                  <span className="net-number">{value} Ft/hó</span>
                )}
              ></NumberFormat>
            </span>
            <span className="gross-price">
              <span className="gross-text">bruttó</span>
              <NumberFormat
                value={Math.round(Financial_data.monthlyFee.price * 1.27)}
                thousandSeparator={" "}
                displayType="text"
                renderText={(value) => (
                  <span className="gross-number">{value} Ft/hó</span>
                )}
              ></NumberFormat>
            </span>
            {Financial_data.loyaltyTime && (
            <span className="loyalty-time">{Financial_data.loyaltyTime}</span>
            )}
          </div>
        </div>
        )}

        <div className={`buzzwords ${!Financial_data && "no-top-margin"}`}>
          {Buzzword_list.map((buzzword, index) => (
            <Fragment key={`fragment-${index}`}>
              {!!index && (
                <div key={`separator-${index}`} className="separator"></div>
              )}
              <div className="buzzword" key={`buzzword-${index}`}>
                {buzzword.text}
              </div>
            </Fragment>
          ))}
        </div>
        {testimonials.length ? (
          <div
            className="testimonials"
            style={{ marginBottom: paymentMethods.length ? "0px" : "100px" }}
          >
            <span className="testimonials-title">Értékelések</span>
            <Slider {...testimonialsSliderSettings}>
              {testimonials.map((testimonial, index) => (
                <div className="testimonial" key={`testiomonial-${index}`}>
                  <div className="stars-container">
                    <StarIcon className="star"></StarIcon>
                    <StarIcon className="star"></StarIcon>
                    <StarIcon className="star"></StarIcon>
                    <StarIcon className="star"></StarIcon>
                    {testimonial.rating === "five" && (
                      <StarIcon className="star"></StarIcon>
                    )}
                  </div>
                  <span className="content">{testimonial.content}</span>
                  <span className="author">{testimonial.author}</span>
                  <span className="company">{testimonial.company}</span>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          ""
        )}
        {paymentMethods.length ? (
          <div className="payment-methods">
            {paymentMethods.map((paymentMethod, index) => (
              <img
                className="payment-method"
                key={`payment-method-${index}`}
                src={process.env.GATSBY_CMS_URL + paymentMethod.image.url}
                alt={paymentMethod.name}
                loading="lazy"
              ></img>
            ))}
          </div>
        ) : (
          ""
        )}
        {penztargep_es_pos && (
          <div className="cashregister-and-pos">
            <h2 className="cashregister-and-pos-title">{penztargep_es_pos.title}</h2>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={penztargep_es_pos.content}
            ></ReactMarkdown>
            <img
              className="desktop-img"
              alt={penztargep_es_pos.image_alt}
              src={process.env.GATSBY_CMS_URL + penztargep_es_pos.image?.url}
              title={penztargep_es_pos.image_description}
              loading="lazy"
            ></img>
            <img
              className="mobile-img"
              alt={penztargep_es_pos.image_alt}
              src={
                process.env.GATSBY_CMS_URL + penztargep_es_pos.image_mobile?.url
              }
              title={penztargep_es_pos.image_description}
              loading="lazy"
            ></img>
          </div>
        )}

        {penztargep && (
          <div className="penztargep">
            <img
              className="desktop-img"
              alt={penztargep.image_alt}
              src={process.env.GATSBY_CMS_URL + penztargep.image.url}
              title={penztargep.image_description}
              loading="lazy"
            ></img>
            <img
              className="mobile-img"
              alt={penztargep.image_alt}
              src={process.env.GATSBY_CMS_URL + penztargep.image_mobile.url}
              title={penztargep.image_description}
              loading="lazy"
            ></img>
            <div className="content">
              <h2 className="penztargep-terminal-title" >{penztargep.title}</h2>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={penztargep.content}
              ></ReactMarkdown>
            </div>
          </div>
        )}

        {terminal && (
          <div className="terminal">
            <div className="content">
              <h2 className="penztargep-terminal-title">{terminal.title}</h2>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={terminal.content}
              ></ReactMarkdown>
            </div>
            <img
              className="desktop-img"
              alt={terminal.image_alt}
              src={process.env.GATSBY_CMS_URL + terminal.image.url}
              title={terminal.image_description}
            ></img>
            <img
              className="mobile-img"
              alt={terminal.image_alt}
              src={process.env.GATSBY_CMS_URL + terminal.image_mobile.url}
              title={terminal.image_description}
            ></img>
          </div>
        )}

        {qr_fizetes && (
          <div
            className="qr"
            style={{
              backgroundImage: `url(${
                process.env.GATSBY_CMS_URL + qr_fizetes.background_image.url
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            aria-label={qr_fizetes.image_alt}
          >
            <div className="content">
              <h2 className="qr-title">{qr_fizetes.title}</h2>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={qr_fizetes.content}
              ></ReactMarkdown>
            </div>
            <img
              className="mobile-img"
              alt={qr_fizetes.image_alt}
              src={
                process.env.GATSBY_CMS_URL +
                qr_fizetes.background_image_mobile.url
              }
              title={qr_fizetes.image_description}
              loading="lazy"
            ></img>
          </div>
        )}

        {szamlazo && (
          <div className="szamlazo">
            <div className="content">
              <h2 className="szamlazo-title">{szamlazo.title}</h2>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={szamlazo.content}
              ></ReactMarkdown>
            </div>
            { szamlazo.image &&
              <img
                alt={szamlazo.image_alt}
                className="desktop-img"
                src={process.env.GATSBY_CMS_URL + szamlazo.image?.url}
                title={szamlazo.image_description}
                loading="lazy"
              ></img>
            }
          </div>
        )}

        <div className="bottom-section">
          <div
            className="desktop-img"
            aria-label={bottomImageAlt}
            style={{
              backgroundImage: `url(${
                process.env.GATSBY_CMS_URL + bottomImage.url
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          
          <div className="total">
            <p className="total-title">{title}</p>
            {bottomDescription && <ReactMarkdown
                className="description"
                rehypePlugins={[rehypeRaw]}
                children={bottomDescription}
              ></ReactMarkdown> }
            {!bottomDescription && <span className="description">{description}</span>}
            
            { Financial_data && <div className="starter-cost">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={dialogSummaryText}
              ></ReactMarkdown>
              <span className="net-price">
                <span className="net-text">nettó</span>
                <NumberFormat
                  value={this.calculatePrice("net")}
                  thousandSeparator={" "}
                  displayType="text"
                  renderText={(value) => (
                    <span className="net-number">{value} Ft</span>
                  )}
                ></NumberFormat>
              </span>
              <span className="gross-price">
                <span className="gross-text">bruttó</span>
                <NumberFormat
                  value={this.calculatePrice("gross")}
                  thousandSeparator={" "}
                  displayType="text"
                  renderText={(value) => (
                    <span className="gross-number">{value} Ft</span>
                  )}
                ></NumberFormat>
              </span>
            </div> }
            { !Financial_data && 
              <CMOButton
                variant="contained"
                text="Érdekel az ajánlat!"
                background_color="#00b8ac"
                color={"white"}
                onClick={this.openModal} />
           }
            { Financial_data && <div className="monthly-cost">
              <div className="left-section">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={dialogMonthlyFeeText}
                ></ReactMarkdown>
                <CMOButton
                  variant="contained"
                  text="Érdekel az ajánlat!"
                  background_color="#00b8ac"
                  color={"white"}
                  onClick={this.openModal} />
              </div>
              <div className="right-section">
                <span className="net-price">
                  <span className="net-text">nettó</span>
                  <NumberFormat
                    value={Financial_data.monthlyFee.price}
                    thousandSeparator={" "}
                    displayType="text"
                    renderText={(value) => (
                      <span className="net-number">{value} Ft/hó</span>
                    )}
                  ></NumberFormat>
                </span>
                <span className="gross-price">
                  <span className="gross-text">bruttó</span>
                  <NumberFormat
                    value={Math.round(Financial_data.monthlyFee.price * 1.27)}
                    thousandSeparator={" "}
                    displayType="text"
                    renderText={(value) => (
                      <span className="gross-number">{value} Ft/hó</span>
                    )}
                  ></NumberFormat>
                </span>
                {Financial_data.loyaltyTime && (
                <span className="loyalty-time">
                  {Financial_data.loyaltyTime}
                </span>
                )}
              </div> 
            </div>}
          </div>
          
        </div>

        { relatedProducts && relatedProducts.length > 0 && (
          <div className="related-products">
            <h2 className="related-products-title">Neked ajánljuk még</h2>
            <Slider {...relatedProductsSliderSettings}>
              {relatedProducts.map((relatedProduct, index) => (
                <a
                  href={
                    this.resolveProductCategorySlug(
                      relatedProduct.productCategory
                    ) + relatedProduct.Slug
                  }
                  className="related-product"
                  key={`related-product-${index}`}
                >
                  <img
                    alt={relatedProduct.title}
                    className="related-product-image"
                    src={
                      process.env.GATSBY_CMS_URL +
                      relatedProduct.ImageColorVariant[0].image.url
                    }
                    loading="lazy"
                  ></img>
                  <span className="related-product-name">
                    {relatedProduct.title}
                  </span>
                  <span className="related-product-description">
                    {relatedProduct.carouselDescription}
                  </span>
                  <span className="related-product-price">
                    {relatedProduct.carouselPrice}
                  </span>
                  <span className="related-product-price-gross">
                    {relatedProduct.carouselPriceGross}
                  </span>
                  <CMOButton
                    variant="contained"
                    background_color={"#5107ff"}
                    text={"Válaszd ezt!"}
                    color={"white"}
                  ></CMOButton>
                  <ul className="bullet-points">
                    {relatedProduct.carouselBulletPoint.map(
                      (bulletPoint, index) => (
                        <li
                          className="bullet-point"
                          key={`bullet-point-${index}`}
                        >
                          {bulletPoint.text}
                        </li>
                      )
                    )}
                  </ul>
                </a>
              ))}
            </Slider>
          </div>
        )}
        
        <Dialog
          classes={{ paper: "MuiDialog-paper-product" }}
          open={this.state.isModalOpen}
          onClose={this.handleModalClose}
        >
          <div className="dialog-content">
            {!this.state.emailSuccess && (
              <h1 className="dialog-title">{formTitle}!</h1>
            )}
            { offerFormDescription ? <ReactMarkdown rehypePlugins={[rehypeRaw]} children={offerFormDescription} ></ReactMarkdown> : "" }
            {!this.state.emailSuccess && (
              <div className="content">
                <div className="form">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
                    onChange={this.onChange}
                  />

                  <TextField
                    label={"Kapcsolattartó neve"}
                    error={this.state.errors.contactNameError}
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...this.state.form,
                          contactName: e.target.value,
                        },
                        errors: {
                          ...this.state.errors,
                          contactNameError: false,
                        },
                        emailSuccess: false,
                      });
                    }}
                    value={this.state.form.contactName || ""}
                  />
                  <TextField
                    label={"Email cím"}
                    variant="outlined"
                    placeholder="fioknev@cmo.hu"
                    error={this.state.errors.emailAddressError ? true : false}
                    helperText={
                      this.state.errors.emailAddressError
                        ? this.state.errors.emailAddressError
                        : ""
                    }
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...this.state.form,
                          emailAddress: e.target.value,
                        },
                        errors: {
                          ...this.state.errors,
                          emailAddressError: false,
                        },
                        emailSuccess: false,
                      });
                    }}
                    value={this.state.form.emailAddress || ""}
                  />
                  <TextField
                    label={"Cégnév"}
                    error={this.state.errors.companyNameError}
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...this.state.form,
                          companyName: e.target.value,
                        },
                        errors: {
                          ...this.state.errors,
                          companyNameError: false,
                        },
                        emailSuccess: false,
                      });
                    }}
                    value={this.state.form.companyName || ""}
                  />
                  <TextField
                    label={"Adószám"}
                    error={this.state.errors.taxNumberError}
                    variant="outlined"
                    placeholder="12345678-1-12"
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...this.state.form,
                          taxNumber: e.target.value,
                        },
                        errors: {
                          ...this.state.errors,
                          taxNumberError: false,
                        },
                        emailSuccess: false,
                      });
                    }}
                    value={this.state.form.taxNumber || ""}
                  />
                  <TextField
                    label={"Telefonszám"}
                    variant="outlined"
                    placeholder="+36301234567"
                    error={this.state.errors.phoneError}
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...this.state.form,
                          phone: e.target.value,
                        },
                        errors: {
                          ...this.state.errors,
                          phoneError: false,
                        },
                        emailSuccess: false,
                      });
                    }}
                    value={this.state.form.phone || ""}
                  />
                <TextField
                    className="text-area"
                    label={"Megjegyzés"}
                    variant="outlined"
                    placeholder="Megjegyzés..."
                    onChange={(e) => {
                      this.setState({
                        form: {
                          ...this.state.form,
                          remarks: e.target.value,
                        },
                        errors: {
                          ...this.state.errors,
                          remarks: false,
                        },
                        emailSuccess: false,
                      });
                    }}
                    value={this.state.form.remarks || ""}
                  />

                  <div
                    className={
                      this.state.errors.agreementError
                        ? "agreement found"
                        : "agreement"
                    }
                  >
                    <FormControlLabel
                      control={
                        <CMOCheckbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({
                                form: { ...this.state.form, agreement: true },
                              });
                            } else {
                              this.setState({
                                form: { ...this.state.form, agreement: false },
                              });
                            }
                          }}
                        />
                      }
                      label={
                        <div className="agreement-text">
                          Elolvastam és elfogadom az{" "}
                          <a
                            href={"https://cmo.hu/adatkezelesi_tajekoztato.pdf"}
                            target={"__blank"}
                            rel={"noopener noreferrer nofollow"}
                          >
                            Adatkezelési tájékoztatót
                          </a>
                        </div>
                      }
                    />
                    <div className="agreement-button-container">
                      <CMOButton
                        variant="contained"
                        background_color={"#5107FF"}
                        text={Button.label}
                        disabled={this.state.buttonDisabled}
                        color={"white"}
                        onClick={async () => {
                          if (await this.formvalidations()) {
                            this.getResult();
                            //this.onChange();
                          }
                        }}
                      ></CMOButton>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Close
              className="close-icon"
              onClick={this.handleModalClose}
            ></Close>

            {this.state.loader ? (
              <ClockLoader
                loading={this.state.loader}
                css={override}
                size={88}
                color={"#4CAF50"}
              />
            ) : this.state.emailSuccess ? (
              <div className="success">
                <SuccessCheckMark
                  id={`id_${this.props.pageContext.content.productKey}`}
                />
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={SuccessText}
                ></ReactMarkdown>
                <CMOButton
                  variant="contained"
                  background_color={"#5107FF"}
                  text={"Bezárás"}
                  color={"white"}
                  onClick={this.handleModalClose}
                ></CMOButton>
              </div>
            ) : (
              ""
            )}
            <Snackbar
              open={this.state.errors.snackbarError}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ paddingTop: "100px" }}
              autoHideDuration={6000}
              onClose={this.handleSnackBarClose}
            >
              <Alert onClose={this.handleSnackBarClose} severity="error">
                Hiba történt!
              </Alert>
            </Snackbar>
          </div>
        </Dialog>
      </LayoutHeaderFooter>
    );
  }
}

export default NewProductPages;
