import React from "react";

import StarIcon from "@material-ui/icons/Star";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import Button from "../Button/Button";

import Slider from "react-slick";

import "./titleDescCarousel.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NavigateNextIcon
      className={className}
      style={{
        ...style,
        color: "#707070",
        width: 100,
        height: 100,
        padding: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NavigateBeforeIcon
      className={className}
      style={{
        ...style,
        color: "#707070",
        width: 100,
        height: 100,
        padding: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

const TitleDescCarousel = ({
  title,
  id,
  description,
  description_2,
  content,
  button_bottom,
}) => {
  const settings = {
    className: "center",
    lazyload: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    swipe: false,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          swipe: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="titleDescCarousel-wrapper" id={id}>
      <LayoutComponent background_color={"white"}>
        <div className="body-content">
          {title && <h1 className="title">{title}</h1>}
          {description && <div className="description">{description}</div>}
          <Slider {...settings}>
            {content.map((item, index) => {
              return (
                <div
                  className={item.highlighted ? "card highlighted" : "card"}
                  key={index}
                >
                  <div
                    className="popular"
                    style={
                      item.highlighted && item.highlight_color
                        ? {
                            backgroundColor: item.highlight_color,
                            borderColor: item.highlight_color,
                          }
                        : null
                    }
                  >
                    <StarIcon /> LEGNÉPSZERŰBB
                  </div>
                  {item.image.url ? (
                    <img
                      src={`${process.env.GATSBY_CMS_URL}${item.image.url}`}
                      alt="item"
                    />
                  ) : (
                    <img src={item.image} alt="item" />
                  )}
                  <p className="titl">{item.title}</p>
                  {item.subtitle && <p className="subtitle">{item.subtitle}</p>}
                  <p className="price">{item.price}</p>
                  {!button_bottom && (
                    <a href={item.url}>
                      <Button
                        variant={"contained"}
                        background_color={
                          item.highlighted && item.highlight_color
                            ? item.highlight_color
                            : "#5107FF"
                        }
                        color={"white"}
                        text={item.button_text}
                      ></Button>
                    </a>
                  )}
                  <div className="desc">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={item.description || item.desc}
                    />
                  </div>
                  {button_bottom && (
                    <a href={item.url}>
                      <Button
                        variant={"contained"}
                        background_color={
                          item.highlighted && item.highlight_color
                            ? item.highlight_color
                            : "#5107FF"
                        }
                        color={"white"}
                        text={item.button_text}
                      ></Button>
                    </a>
                  )}
                </div>
              );
            })}
          </Slider>
          {description_2 && (
            <div className="description">
              A CMO által ajánlott számlanyomtatók maximálisan kompatibilisek a
              CMO számlázójával, és az ajánlott érintésmentes fizetési
              moduljainkkal.
            </div>
          )}
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleDescCarousel;
